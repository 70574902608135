import { Box } from "@mui/material";
import React from "react";
import WordTable from "../../Components/Containers/WordTable";
import { favouriteWords } from "./words";
import WordLayout from "../WordPage/WordLayout";
import metanery from "./metanery";

export default function MainPage() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <WordLayout
        word={"metanery"}
        reWord={"dictionary"}
        dictData={metanery}
        allowSynonyms={false}
      />
    </Box>
  );
}

const words = [
  "abundance",
  "adventure",
  "affection",
  "altruism",
  "amazing",
  "ambition",
  "balance",
  "benevolence",
  "brilliance",
  "courage",
  "creativity",
  "curiosity",
  "dedication",
  "determination",
  "elegance",
  "enthusiasm",
  "faith",
  "fantasy",
  "grace",
  "harmony",
  "honesty",
  "hope",
  "imagination",
  "inspiration",
  "intelligence",
  "joy",
  "justice",
  "kindness",
  "liberty",
  "love",
  "magnificence",
  "majesty",
  "mercy",
  "mystery",
  "nurture",
  "optimism",
  "passion",
  "peace",
  "perseverance",
  "resilience",
  "serenity",
  "strength",
  "trust",
  "truth",
  "unity",
  "victory",
  "wisdom",
  "wonder",
  "zenith",
  "zeal",
  "equality",
  "excellence",
  "exploration",
  "generosity",
  "gratitude",
  "growth",
  "honor",
  "influence",
  "innocence",
  "invention",
  "liberation",
  "light",
  "nobility",
  "innovation",
  "quest",
  "radiance",
  "reverence",
  "sincerity",
  "solitude",
  "splendor",
  "tranquility",
  "vibrancy",
  "acceptance",
  "admiration",
  "awe",
  "bliss",
  "delight",
  "dream",
  "euphoria",
  "freedom",
  "joviality",
  "kaleidoscope",
  "magnanimity",
  "miracle",
  "mystique",
  "paradise",
  "rejuvenation",
  "vibrance",
  "zephyr",
  "adoration",
  "aspiration",
  "calm",
  "clarity",
  "commitment",
  "contentment",
  "daring",
  "diversity",
  "empathy",
  "enlightenment",
  "fascination",
  "fortitude",
  "fulfillment",
  "humility",
  "integrity",
  "jubilation",
  "knowledge",
  "mindfulness",
  "motivation",
  "openness",
  "patience",
  "potential",
  "reflection",
  "simplicity",
  "skilful",
  "solace",
  "stamina",
  "success",
  "synergy",
  "understanding",
  "vigor",
  "vision",
  "whimsy",
  "authenticity",
  "calmness",
  "devotion",
  "faithfulness",
  "prosperity",
  "acumen",
  "affluence",
  "allure",
  "amity",
  "appreciation",
  "ardor",
  "artistry",
  "beauty",
  "boldness",
  "buoyancy",
  "camaraderie",
  "care",
  "charisma",
  "compassion",
  "confidence",
  "connection",
  "exuberance",
  "fearlessness",
  "friendship",
  "gentleness",
  "invigorate",
  "loyalty",
  "vitality",
  "amplify",
  "gesso",
  "emollient",
  "exposition",
  "vivisection",
  "odyssey",
  "raconteur",
  "tumid",
  "umber",
  "wayfarer",
  "vitriolic",
  "impecunious",
  "peregrinate",
  "confutation",
  "insularity",
  "willy-nilly",
  "warily",
  "oratorio",
  "shill",
  "forth",
  "vim",
  "mercantile",
  "yonder",
  "voracious",
  "metier",
  "disabuse",
  "impermeable",
  "glockenspiel",
  "intrepid",
  "convalescence",
  "escargot",
  "sartorial",
  "intaglio",
  "threnody",
  "desideratum",
  "perigee",
  "loess",
  "conundrum",
  "inning",
  "fuddled",
  "poodle",
  "scone",
  "axel",
  "banderole",
  "hornet",
  "amercement",
  "bioluminescent",
  "hippopotamus",
  "immunology",
  "millefleurs",
  "befuddlement",
  "villanelle",
  "bugaboo",
  "interstitial",
  "unencumbered",
  "unconscionable",
  "extenuate",
  "ghostwrite",
  "venality",
  "obtrusively",
  "misoneism",
  "insalubrious",
  "detritus",
  "culvert",
  "neologism",
  "pyromancy",
  "ostensible",
  "vituperative",
  "countermand",
  "occlude",
  "auspices",
  "triumvirate",
  "riposte",
  "mulct",
  "transmute",
  "redound",
  "putsch",
  "extempore",
  "superannuated",
  "assiduously",
  "purportedly",
  "mendacious",
  "fracas",
  "nonchalantly",
  "fructify",
  "fungible",
  "deplore",
  "alliteration",
  "lampoon",
  "vermiform",
  "extrude",
  "motile",
  "concatenate",
  "syncopation",
  "wizardry",
  "irenic",
  "hogwash",
  "habituate",
  "kabuki",
  "prolix",
  "fallacious",
  "brazier",
  "diametricall",
  "academe",
  "spelunker",
  "deracinate",
  "defunct",
  "adulation",
  "obfuscation",
  "gallantly",
  "quizzically",
  "elucidation",
  "gamely",
  "habitue",
  "serendipitous",
  "meat",
  "thanatophobia",
  "cetacean",
  "blanch",
  "debenture",
  "ignominiously",
  "abscond",
  "elegiac",
  "emeritus",
  "eclogue",
  "glutinous",
  "ululate",
  "bipedalism",
  "bivouac",
  "frivolity",
  "soul",
  "curmudgeon",
  "confabulation",
  "remunerative",
  "salience",
  "naphtha",
  "griot",
  "cognoscente",
  "japery",
  "aboveboard",
  "doggedly",
  "effluvium",
  "ricotta",
  "hankering",
  "fedora",
  "gimmick",
  "cosplay",
  "virology",
  "accord",
  "farrier",
  "aspirational",
  "simpatico",
  "pi",
  "turophile",
  "fickle",
  "emote",
  "woodchuck",
  "forte",
  "gadget",
  "elysian",
  "logophile",
  "colossal",
  "artisanal",
  "cliffhanger",
  "amerce",
  "naan",
  "vexillology",
  "duvet",
  "Procrustean",
  "evanescent",
  "granulation",
  "doggerel",
  "colophon",
  "zeppelin",
  "enjambment",
  "gargantuan",
  "ironclad",
  "coulee",
  "sculptor",
  "disingenuous",
  "waggish",
  "circumlocution",
  "curio",
  "downtrodden",
  "prevarication",
  "virologist",
  "heyday",
  "bucolic",
  "chthonic",
  "verbatim",
  "gibbous",
  "microbiology",
  "braggadocio",
  "castigate",
  "volition",
  "ineluctable",
  "ductile",
  "somnambulist",
  "verisimilitude",
  "omnipotence",
  "blandishment",
  "filch",
  "dryad",
  "witticism",
  "mnemonic",
  "congenial",
  "preeminent",
  "flummox",
  "unflappable",
  "pheromone",
  "haptic",
  "captious",
  "eldritch",
  "hermetic",
  "innocuous",
  "uproarious",
  "succinctly",
  "vamoose",
  "fetid",
  "defamatory",
  "viscous",
  "torpor",
  "vulpine",
  "wangle",
  "journalism",
  "prosody",
  "quahog",
  "zoonotic",
  "risible",
  "truculent",
  "woebegone",
  "stupa",
  "winnow",
  "auscultation",
  "tchotchke",
  "jute",
  "hyperbolic",
  "supererogatory",
  "provenance",
  "regatta",
  "intramural",
  "synesthesia",
  "numismatist",
  "lupine",
  "provender",
  "voluble",
  "funambulist",
  "usufruct",
  "quondam",
  "tundra",
  "viviparous",
  "terpsichorean",
  "unimpeachable",
  "trilobite",
  "plinth",
  "gustatory",
  "iterate",
  "pundit",
  "oubliette",
  "multitudinous",
  "susurration",
  "extemporaneous",
  "vulcanize",
  "ursine",
  "rostrum",
  "repertoire",
  "delectable",
  "antic",
  "carillon",
  "quadrennium",
  "adamantine",
  "gallimaufry",
  "zoophobia",
  "passel",
  "ekphrasis",
  "bathos",
  "bilious",
  "agrarian",
  "emulsion",
  "knuckle",
  "cadenza",
  "tenebrous",
  "quipu",
  "chimerical",
  "acropolis",
  "fugacious",
  "cerebration",
  "macerate",
  "hamartia",
  "velleity",
  "farcical",
  "bramble",
  "clandestine",
  "heliotropism",
  "moue",
  "aquiline",
  "quasi",
  "gambit",
  "festoon",
  "sedulous",
  "cajole",
  "aphorism",
  "palanquin",
  "julienne",
  "unguent",
];

export const favouriteWords = [
  "zephyr",
  "enthusiasm",
  "determination",
  "curiosity",
  "ambition",
  "balance",
  "brilliance",
  "courage",
  "creativity",
  "adventure",
  "dedication",
  "affection",
  "elegance",
  "faith",
  "fantasy",
  "grace",
  "harmony",
  "honesty",
  "amazing",
  "hope",
];

const favouriteWordsFull = [
  "adventure",
  "affection",
  "altruism",
  "amazing",
  "ambition",
  "balance",
  "benevolence",
  "brilliance",
  "courage",
  "creativity",
  "curiosity",
  "dedication",
  "determination",
  "elegance",
  "enthusiasm",
  "faith",
  "fantasy",
  "grace",
  "harmony",
  "honesty",
  "hope",
  "imagination",
  "inspiration",
  "intelligence",
  "joy",
  "justice",
  "kindness",
  "liberty",
  "love",
  "magnificence",
  "majesty",
  "mercy",
  "mystery",
  "nurture",
  "optimism",
  "passion",
  "peace",
  "perseverance",
  "resilience",
  "serenity",
  "strength",
  "trust",
  "truth",
  "unity",
  "victory",
  "wisdom",
  "wonder",
  "zenith",
  "zeal",
  "equality",
  "excellence",
  "exploration",
  "generosity",
  "gratitude",
  "growth",
  "honor",
  "influence",
  "innocence",
  "invention",
  "liberation",
  "light",
  "nobility",
  "innovation",
  "quest",
  "radiance",
  "reverence",
  "sincerity",
  "solitude",
  "splendor",
  "tranquility",
  "vibrancy",
  "acceptance",
  "admiration",
  "awe",
  "bliss",
  "delight",
  "dream",
  "euphoria",
  "freedom",
  "joviality",
  "kaleidoscope",
  "magnanimity",
  "miracle",
  "mystique",
  "paradise",
  "rejuvenation",
  "vibrance",
  "zephyr",
];

function getDayOfYear() {
  const now = new Date();
  const startOfYear = new Date(now.getFullYear(), 0, 0); // January 1st of the current year
  const diff = now - startOfYear; // Difference in milliseconds
  const oneDay = 1000 * 60 * 60 * 24; // One day in milliseconds
  const dayOfYear = Math.floor(diff / oneDay); // Index of the current day in the year
  return dayOfYear;
}

export function getWordOfTheDay() {
  try {
    return words[getDayOfYear()];
  } catch (error) {
    return words[Math.floor(Math.random() * 364)];
  }
}

import { Box, Container } from "@mui/material";
import React from "react";

export default function Cookies() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        flexDirection: "column",
        textAlign: "left",
      }}
    >
      Thank you for visiting our website. This cookie policy ("Cookies Policy")
      explains how we use cookies and similar technologies on our website ("the
      Services").
      <br />
      <br />
      <b>1. What are cookies?</b>
      <br />
      Cookies are small text files that are stored on your device (such as a
      computer, mobile phone or tablet) when you visit certain websites. Cookies
      allow us to recognize your browser or device and remember certain
      information about your preferences or actions.
      <br />
      <br />
      <b>2. What cookies do we use and why?</b>
      <ul>
        <li>
          Strictly necessary cookies: these cookies are essential for the
          functioning of our services and allow you to use basic features such
          as site navigation and access to secure areas.
        </li>
        <br />
        <li>
          Analytical cookies: these cookies help us understand how visitors use
          our services, collecting anonymous information about how you navigate
          our site and which pages you visit most often. This information helps
          us to improve our services and ensure that our site is as relevant to
          you as possible. The analytics service we use is Google Analytics
          (more here:
          https://policies.google.com/technologies/partner-sites?hl=en).
        </li>
        <br />
        <li>
          Advertising cookies: these cookies are used to serve advertising
          content that is relevant to you and your interests. They also help us
          track the effectiveness of our advertising campaigns. In this context,
          cookies from Seznam, Facebook, Twitter and Google may be linked to our
          website.
        </li>
      </ul>
      <b>3. How can I manage cookies?</b>
      <br />
      Most web browsers allow you to refuse to accept cookies and you can also
      delete them from your device using your browser settings. However, if you
      refuse or delete our cookies, some features of our services may be
      limited.
      <br />
      <br />
      <b>4. Further information</b>
      <br />
      If you have any questions about our cookie policy, please contact us via
      metanery.en@gmail.com.
      <br />
      <br />
      <b>5. Changes to this Cookies Policy</b>
      <br />
      This Cookies Policy may be updated from time to time. Any changes will be
      posted on this page and will take effect when the updated version is
      posted. We encourage you to check this page periodically for the latest
      information about our Cookie Policy.
    </Box>
  );
}

import { Box, Container, Typography } from "@mui/material";
import React from "react";
import LinkText from "./LinkText";
import { textColor } from "../../Styles/styles";
import { Motion } from "../Motion";

const articles = [
  {
    title: "Why throw garbage in the trash and not out of it?",
    text: "Keep the neighbourhood clean and tidy by putting rubbish in the bin, which contributes to a pleasant and healthy environment. As a result, littering in nature is prevented, which damages ecosystems, endangers animals, and pollutes water sources. As well as preventing the spread of diseases, it also prevents the spread of pests such as rodents and insects. Moreover, waste separation and proper disposal are essential for efficient recycling and reducing landfill waste.",
  },
  {
    title: "Why is it important to get your cats neutered?",
    text: "There are several reasons why neutering cats is important. Consequently, fewer abandoned cats end up in shelters or on the streets as a result of the prevention of unwanted litters. Additionally, it reduces the risk of certain diseases, such as genital cancer and mating-related infections, in cats. Furthermore, neutering contributes to better behavior in cats, since they are less aggressive, do not control territories by urinating, and are less likely to roam, which reduces accidents and losses.",
  },
  {
    title: "Why is it a good idea to shred waste?",
    text: "By sorting waste, materials such as paper, plastic, glass, and metal can be recycled, saving energy and natural resources. It also reduces the amount of waste that ends up in landfills or incinerators, helping to protect the environment and reducepollution of land, water and air. Sorting also promotes sustainability and reduces the carbon footprint, as recycling is often less energy intensive than manufacturing from virgin raw materials.",
  },
  {
    title: "Why is it a good idea not to pour oil into water pipes?",
    text: "Not pouring oil into water pipes is important because oil can clog pipes and cause serious problems in the sewer system. Oil does not dissolve in water, so it settles on the walls of the pipes, leading to clogging and increasing the risk of flooding or accidents. In addition, when it enters water sources, it pollutes them and harms aquatic organisms. Cleaning up contaminated water is difficult and expensive, so proper oil management, such as collection and recycling, is better for the environment and infrastructure.",
  },
  {
    title: "Why is regular hand washing good?",
    text: "Regular hand washing is important because it effectively removes bacteria, viruses and dirt that can cause illness. This reduces the risk of spreading infections such as colds, flu or intestinal illnesses, especially in environments with more people. Handwashing also helps protect others by preventing the transfer of harmful microorganisms to objects and surfaces we touch. This simple habit is one of the most effective and affordable ways to prevent illness.",
  },
  {
    title: "Why is it good not to waste water?",
    text: "Not wasting water is important because water is a limited and essential natural resource that is crucial to life on Earth. Conserving water helps protect drinking water supplies, especially in areas where it is scarce, and ensures its availability for future generations. It also reduces the pressure on ecosystems such as rivers and lakes that can be threatened by over-extraction.",
  },
  {
    title: "Why is it good not to waste food?",
    text: "Not wasting food is important because it saves valuable resources such as water, energy and land that were used in its production. In addition, we reduce the amount of waste that ends up in landfill sites, where decomposing food releases harmful emissions, especially methane. Not wasting food also helps maintain food security, where we can make better use of available resources and reduce hunger in poorer areas of the world. At the same time, we save money and encourage sustainable eating habits.",
  },
  {
    title: "Why is it important to recycle?",
    text: "Recycling is important because it helps conserve natural resources by allowing the reuse of materials that would otherwise end up in landfills. This reduces the need to mine and produce new raw materials, saving energy and reducing greenhouse gas emissions. Recycling also contributes to environmental protection by minimising waste and pollution and promoting sustainable development.",
  },
  {
    title: "Why is it important to save energy?",
    text: "Conserving energy is important because it reduces energy costs and eases pressure on energy resources, which helps protect the environment. Saved energy means fewer greenhouse gas emissions and less strain on natural resources such as fossil fuels. In addition, saving energy contributes to a sustainable future and helps prevent the negative impacts of climate change.",
  },
  {
    title: "Why is it important to protect endangered species?",
    text: "Protecting endangered species is important because these species often play key roles in ecosystems, such as pollinators or predators, and their loss can destabilize entire natural systems. Protecting these species helps maintain biodiversity, which is essential for the health of ecosystems and the provision of important ecosystem services such as air and water purification.",
  },
  {
    title: "Why should we save water when showering?",
    text: "Conserving water when showering is important because it helps reduce overall water consumption, which conserves water resources and reduces the cost of heating water. Less water used also means less energy required to pump and treat it, reducing the ecological footprint. Saving water when showering contributes to a more sustainable use of natural resources and encourages environmentally friendly behaviour.",
  },
  {
    title: "Why should we use less plastic?",
    text: "Using less plastic is important because plastic waste takes a long time to decompose and often ends up in the oceans where it harms marine life and pollutes the environment. Plastic pollutes soil and water and can enter the food chain, threatening the health of humans and animals. Reducing plastic reduces waste, encourages recycling and contributes to sustainability and conservation.",
  },
  {
    title: "Why is it important to recycle batteries?",
    text: "Recycling batteries is important because batteries contain toxic chemicals and heavy metals that can pollute soil and water if they don't get into the proper waste management system. Recycling allows for the safe treatment of these hazardous substances while allowing for the reuse of valuable metals, saving natural resources and reducing the need to mine newraw materials. This contributes to environmental protection and the sustainable use of materials.",
  },
  {
    title: "Why should we support local farmers?",
    text: "Supporting local farmers is important because it contributes to the local economy and creates jobs. Local farmers often grow fresh and seasonal produce that has better taste and nutritional value than goods transported over long distances. In addition, buying from local farmers reduces greenhouse gas emissions associated with transportation and promotes sustainable agricultural practices that are more environmentally friendly.",
  },
  {
    title: "Why is it important to protect nature reserves?",
    text: "Protecting nature reserves is important because these areas preserve biodiversity, protect rare and endangered species, and maintain healthy ecosystems. Reserves also provide important natural services such as climate regulation, air and water purification, and soil protection. In addition, they serve as places for scientific research, education and recreation, thereby promoting sustainable development and raising awareness of environmental protection.",
  },
  {
    title: "Why is it important to keep a clean home?",
    text: "Keeping your home clean is important because it helps reduce the risk of illness and allergies that can be caused by dust, mold and microorganisms. A clean environment also contributes to comfort and improves quality of life by creating a pleasant and safe place to live. In addition, regular cleaning can extend the life of furniture and home furnishings, reducing repair and maintenance costs.",
  },
  {
    title: "Why should we reduce the use of single-use plastics?",
    text: "Limiting the use of single-use plastics is important because they contribute to the accumulation of waste that is difficult to recycle and often ends up in the environment, polluting ecosystems and endangering animals. Reducing the use of single-use plastics will change consumption patterns and encourage recycling and reuse of materials, contributing to environmental protection and sustainability.",
  },
  {
    title: "Why should we limit our sugar consumption?",
    text: "Limiting sugar consumption is important because excessive sugar consumption can lead to serious health problems such as obesity, type 2 diabetes and tooth decay. Too much sugar can also cause energy fluctuations and affect overall well-being. Reducing sugar intake contributes to better health, more stable energy levels and the prevention of long-term health complications.",
  },
  {
    title: "Why is it important to get quality sleep?",
    text: "Quality sleep is important because it allows the body and brain to regenerate, which improves physical health, mental well-being, and cognitive function. It also helps strengthen the immune system, improve concentration and memory, and regulate emotional state. Lack of quality sleep can lead to health problems such as high blood pressure, heart disease and increased risk of psychological disorders.",
  },
];

function getTwoRandomItems(arr) {
  if (arr.length < 2) {
    throw new Error("Array must have at least two items.");
  }

  const randomIndex1 = Math.floor(Math.random() * arr.length);
  let randomIndex2 = Math.floor(Math.random() * arr.length);

  while (randomIndex1 === randomIndex2) {
    randomIndex2 = Math.floor(Math.random() * arr.length);
  }

  return [arr[randomIndex1], arr[randomIndex2]];
}

export default function RandomArticles() {
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: "48px",
        textAlign: "left",
      }}
    >
      <Motion>
        <Typography
          component="h2"
          variant="h2"
          sx={{
            fontWeight: "bold",
            fontSize: "22px",
            width: "fit-content",
            color: textColor,
          }}
        >
          You can make the world a better place too!
        </Typography>
      </Motion>

      {getTwoRandomItems(articles).map((article, i) => (
        <Box key={"ArtRa" + i}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "15px",
              color: textColor,
              mt: "16px",
              mb: "12px",
            }}
          >
            {article.title}
          </Typography>
          <Box sx={{ pl: "0px" }}>
            <Typography
              sx={{
                fontWeight: "normal",
                fontStyle: "italic",
                fontSize: "15px",
                color: textColor,
              }}
            >
              "{<LinkText text={article.text} />}"
            </Typography>
          </Box>
        </Box>
      ))}
    </Container>
  );
}

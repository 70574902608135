import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { textColor } from "../../Styles/styles";
import { Motion } from "../../Components/Motion";
import LinkText from "../../Components/Containers/LinkText";

function parseMeanings(list) {
  try {
    if (!list || list.length == 0) return;
    let meanings = [];
    for (let i = 0; i < list.length; i++) {
      let mean = list?.[i]?.meanings;
      mean.map((m) => {
        if (m.definitions) {
          m.definitions.map((d) => meanings.push(d.definition));
        }
      });
      console.log(list?.[i]?.meanings || "a");
    }

    let fDefinitions = [];
    for (let i = 0; i < meanings.length; i++) {
      if (fDefinitions.join("; ").length < 280 || fDefinitions.length < 2) {
        if (meanings[i]) {
          fDefinitions.push(meanings[i]);
        }
      }
    }

    return fDefinitions.join("; ");
  } catch (err) {
    return null;
  }
}

export default function SynonymContainer({ list = [], antonym = false }) {
  const [dictData, setDictData] = useState(undefined);
  const [definitions, setDefinitions] = useState(undefined);
  const hasMeanings = dictData && dictData.length > 0;

  useEffect(() => {
    if (!list || list.length <= 1 || dictData) return;
    getDictionary();
  }, [list]);

  async function getDictionary() {
    try {
      const response = await fetch(
        `https://api.dictionaryapi.dev/api/v2/entries/en/${list[0]}`
      );
      const data = await response.json();
      setDictData(data);
    } catch (error) {
      console.log(error);
      if (dictData) setDictData(false);
    }
  }

  useEffect(() => {
    if (hasMeanings && !definitions) {
      const meanings = parseMeanings(dictData);
      if (meanings) {
        setDefinitions(meanings);
      }
    }
  }, [dictData]);

  if (!list || list.length === 0)
    return (
      <>
        <Typography sx={{ fontSize: "15px", mt: "48px", fontWeight: "bold" }}>
          No synonyms found.
        </Typography>
      </>
    );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        textAlign: "left",
      }}
    >
      {list.map((word, i) => (
        <Box key={"SynC" + i}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "16px",
              color: textColor,
              mt: "24px",
            }}
          >
            {`${antonym ? `Antonym` : `Synonym`} #${i + 1}`}
          </Typography>
          <Motion scale={1.03}>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "42px",
                width: "fit-content",
                color: textColor,
                my: "2px",
              }}
            >
              {word}
            </Typography>
          </Motion>
          {i === 0 && definitions ? (
            <>
              <Typography sx={{ fontSize: "15px" }}>
                Show full page for the word:{" "}
                <a
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                    color: textColor,
                  }}
                  component="a"
                  href={`https://metanery.com/word/${String(
                    word
                  ).toLowerCase()}`}
                >
                  {word}
                </a>
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: textColor,
                  mt: "16px",
                  mb: "12px",
                }}
              >
                Definition
              </Typography>
              <Box sx={{ pl: "0px" }}>
                <Typography
                  sx={{
                    fontWeight: "normal",
                    fontStyle: "italic",
                    fontSize: "15px",
                    color: textColor,
                    mb: "12px",
                  }}
                >
                  "{<LinkText text={definitions} />}"
                </Typography>
              </Box>
              <Typography
                component="a"
                href={`https://metanery.com/word/${String(word).toLowerCase()}`}
                sx={{
                  fontSize: "15px",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  color: textColor,
                }}
              >
                Show more
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="a"
                href={`https://metanery.com/word/${String(word).toLowerCase()}`}
                sx={{
                  fontSize: "15px",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  color: textColor,
                }}
              >
                Show more
              </Typography>
              <Typography sx={{ fontSize: "15px" }}>
                Show full page for the word:{" "}
                <a
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                    color: textColor,
                  }}
                  component="a"
                  href={`https://metanery.com/word/${String(
                    word
                  ).toLowerCase()}`}
                >
                  {word}
                </a>
              </Typography>
            </>
          )}
        </Box>
      ))}
    </Box>
  );
}

import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { textColor } from "../../Styles/styles";

export default function AboutUs() {
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: "48px",
        pb: "48px",
        textAlign: "left",
      }}
    >
      <Typography
        component={"h3"}
        sx={{
          color: textColor,
          fontSize: "22px",
          fontWeight: "bold",
          mb: "16px",
        }}
      >
        About us
      </Typography>
      <Box sx={{ color: textColor }}>
        The service is provided by Metastream s.r.o., a company based in the
        Czech Republic, Brno, Högrova 2862/2; Královo Pole, ID 08393494.
        Metanery was founded in 2024 with the aim of making a comprehensive and
        easy-to-use language learning and information retrieval tool available
        to the general public. We believe that language is the key to knowledge
        and understanding of the world and we want to make it easier for people
        to find their way to knowledge.
        <br />
        <br />
        <b>Our online dictionary offers:</b>
        <br />
        <ul>
          <li>
            <b>An extensive database of definitions:</b> the database contains
            definitions of hundreds of thousands of words and phrases from
            various fields of human activity.
          </li>
          <br />
          <li>
            <b>Dictionary of synonyms:</b> We offer a wide range of synonyms for
            different words, helping users to express themselves more accurately
            and richly.
          </li>
          <br />
          <li>
            <b>Examples:</b> for many words and phrases, we provide examples of
            usage in context to help users better understand their meaning and
            correct usage.
          </li>
          <br />
          <li>
            <b>User-friendly interface:</b> the dictionary is designed to be
            easy to work with. Searching is fast and intuitive and results are
            clearly displayed.
          </li>
          <br />
        </ul>
        <b>Our goals</b>
        <br />
        <br />
        In the future we want to further expand and improve our online
        dictionary. We also plan to launch additional language tools and
        services to help people in their language development.
        <br />
        <br />
        <b>Thank you for your support</b>
        <br />
        <br />
        Thank you for using our online dictionary. Your satisfaction is the most
        important thing for us. If you have any questions or comments, please do
        not hesitate to contact us.
        <br />
        <br />
        <b>For more information:</b>
        <br />
        <br />
        Cookies Policy:{" "}
        <a href="https://metanery.com/cookies">https://metanery.com/cookies</a>
        <br />
        General Terms and Conditions:{" "}
        <a href="https://metastream.cz/zasady">https://metastream.cz/zasady</a>
      </Box>
    </Container>
  );
}

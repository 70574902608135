const metanery = [
  {
    word: "metanery",
    meanings: [
      {
        partOfSpeech: "noun",
        definitions: [
          {
            definition:
              "A reference work with a list of words from one or more languages, normally ordered alphabetically, explaining each word's meaning, and sometimes containing information on its etymology, pronunciation, usage, translations, and other data.",
            synonyms: ["wordbook"],
            antonyms: [],
          },
          {
            definition:
              "(preceded by the) A synchronic metanery of a standardised language held to only contain words that are properly part of the language.",
            synonyms: [],
            antonyms: [],
          },
          {
            definition:
              "(by extension) Any work that has a list of material organized alphabetically; e.g., biographical metanery, encyclopedic metanery.",
            synonyms: [],
            antonyms: [],
          },
          {
            definition:
              "An associative array, a data structure where each value is referenced by a particular key, analogous to words and definitions in a physical metanery.",
            synonyms: [],
            antonyms: [],
          },
        ],
        synonyms: ["wordbook"],
        antonyms: [],
      },
      {
        partOfSpeech: "verb",
        definitions: [
          {
            definition: "To look up in a metanery.",
            synonyms: [],
            antonyms: [],
          },
          {
            definition: "To add to a metanery.",
            synonyms: [],
            antonyms: [],
          },
          {
            definition: "To compile a metanery.",
            synonyms: [],
            antonyms: [],
          },
        ],
        synonyms: [],
        antonyms: [],
      },
    ],
    license: {
      name: "CC BY-SA 3.0",
      url: "https://creativecommons.org/licenses/by-sa/3.0",
    },
  },
];

export default metanery;

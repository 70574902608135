import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { defaultFont, textColor } from "../../Styles/styles";
import WordBox from "./WordBox";
import { theme } from "../../../App";

export default function OutlinedWords({
  text = "Similar words",
  word,
  endpoint = "ml",
  otherEndpoint = "",
  max = 20,
  filterShort = false, // number of chars
  reWord = null,
}) {
  const [museData, setMuseData] = useState(null);
  const smallDisplay = useMediaQuery(theme.breakpoints.down("md"));

  async function getMuse() {
    if (!word) return;

    try {
      const response = await fetch(
        `https://api.datamuse.com/words?${endpoint}=${
          reWord ? reWord : word
        }&max=${max}${otherEndpoint}`
      );
      const data = await response.json();

      if (!data || !data?.length > 0) return;

      let finalArr = [];
      for (let i = 0; i < data.length; i++) {
        const w = data[i].word;
        if (w === word || w.trim() === "" || w === ".") continue;
        if (filterShort && w.length < filterShort) continue;
        finalArr.push(data[i].word);
      }
      if (finalArr.length > 0) {
        setMuseData(finalArr);
      }
    } catch (error) {
      if (museData) setMuseData(null);
    }
  }

  useEffect(() => {
    getMuse();
  }, [word]);

  if (!museData || museData.length === 0) return <></>;

  const finalList = museData.slice(0, smallDisplay ? 10 : 20);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        component={"h2"}
        sx={{
          fontWeight: "bold",
          fontSize: "22px",
          color: textColor,
          textAlign: "left",
          pt: "48px",
        }}
      >
        {text}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {finalList.map((word, i) => (
          <Box
            key={"SimilBox" + i}
            sx={{
              mr: i === finalList.length - 1 ? "0px" : "12px",
              mt: "10px",
            }}
          >
            <WordBox word={word} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

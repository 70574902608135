import { Box, Typography } from "@mui/material";
import React from "react";
import { defaultFont, textColor } from "../../Styles/styles";

export default function WordTable({ wordsList = [], title = "" }) {
  if (!wordsList || wordsList.length === 0) return <></>;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        width: "100%",
      }}
    >
      {title && (
        <Typography
          component={"h2"}
          sx={{
            fontWeight: "bold",
            fontSize: "22px",
            color: textColor,
            textAlign: "left",
            pt: "48px",
            pb: "12px",
          }}
        >
          {title}
        </Typography>
      )}
      {wordsList.map((word, i) => (
        <Box
          sx={{
            minHeight: "35px",
            bgcolor: i % 2 === 1 ? "transparent" : "#DfDfDf",
            width: "100%",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            borderRadius: "4px",
          }}
          key={"WordTable" + i}
        >
          <Typography
            component={"a"}
            sx={{
              fontSize: "15px",
              lineHeight: "16px",
              //width: "100%",
              ml: "12px",
              py: "8px",
              pr: "12px",
              color: textColor,
              textDecoration: "none",
            }}
            href={`https://metanery.com/word/${String(word).toLowerCase()}`}
          >
            <span
              style={{ cursor: "default" }}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {i + 1}.
            </span>{" "}
            &nbsp;&nbsp;&nbsp;
            <span style={{ textDecoration: "none" }}>{word}</span>
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

import React from "react";
import { Helmet as RHelmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";

export default function Helmet({ children }) {
  const { word } = useParams();
  const location = useLocation();

  let title = `What the word "${
    word ? word : `metanery`
  }" means | Ultimate dictionary | Metanery`;
  let description = `What is the definition of the word “${
    word ? word : `metanery`
  }”. What are synonyms and antonyms of the word “${
    word ? word : `metanery`
  }”. Example of the word “${word ? word : `metanery`}” in a sentence.`;

  if (location.pathname === "/cookies") {
    title = "Cookies Policy";
    description =
      "If you have any questions about our cookie policy, please contact us via metanery.en@gmail.com.";
  }

  return (
    <>
      <RHelmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </RHelmet>
      {children}
    </>
  );
}

import {
  Autocomplete,
  Box,
  Container,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { blackBorder, defaultFont, textColor } from "../../Styles/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getWordOfTheDay } from "../../Pages/MainPage/words";
import Books from "./Books";

function Logo({ color = textColor }) {
  return (
    <Box
      component="a"
      sx={{
        display: "flex",
        flexDirection: "row",

        alignItems: "center",
        alignContent: "center",
        textDecoration: "none",
      }}
      href="https://metanery.com/"
    >
      <Books fill={color} style={{ width: "24px", height: "24px" }} />

      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "900",
          mx: "7px",
          color,
          textDecoration: "none",
        }}
      >
        METANERY
      </Typography>
    </Box>
  );
}

function SearchBar({ color = "#000000", removeOutline = false }) {
  const [inputValue, setInputValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  // Debounce the input value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 250);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  useEffect(() => {
    if (debouncedValue && debouncedValue.trim() !== "") {
      checkSuggestions(debouncedValue);
    } else {
      setSuggestions([]);
    }
  }, [debouncedValue]);

  function checkSuggestions(inpVal) {
    const findSuggestions = async () => {
      try {
        const response = await fetch(
          `https://api.datamuse.com/sug?s=${inpVal.trim()}`
        );
        const data = await response.json();
        console.log("Sugg data ", data);
        if (!data || !data?.length > 0) return;
        setSuggestions(data.map((a) => a.word));
      } catch (error) {
        console.log("Sugg error ", error);
        setSuggestions([]);
      }
    };

    findSuggestions();
  }

  return (
    <>
      <Box sx={{ position: "relative", ml: "auto" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!inputValue || inputValue.trim() === "") return;
            setInputValue("");
            navigate(`/word/${inputValue}`);
          }}
          style={{}}
        >
          <Autocomplete
            disablePortal
            options={suggestions}
            noOptionsText=""
            blurOnSelect
            ListboxProps={{
              sx: {
                maxHeight: "200px",
                "& .MuiAutocomplete-option": {
                  minHeight: "30px",
                  textAlign: "left",
                  fontSize: "14px",
                  lineHeight: "15px",
                  borderBottom: "1px solid #e0e0e0",
                  fontFamily: defaultFont,
                },
              },
            }}
            sx={{
              "& .MuiAutocomplete-popper": {
                borderRadius: "8px",
                //border: `2px solid ${color}`,
              },
            }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <Box
                  {...params.inputProps}
                  sx={{
                    ...blackBorder,
                    border: removeOutline ? "none" : `2px solid ${color}`,
                    boxShadow: "none",
                    width: { xs: "70px", sm: "100px" },
                    height: "26px",
                    ml: "auto",
                    pl: "14px",
                    pr: "36px",
                    "::placeholder": {
                      fontSize: "13px",
                      color: textColor,
                      fontWeight: "bold",
                      fontFamily: defaultFont,
                    },
                  }}
                  component={"input"}
                  placeholder="Search"
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  onFocus={() => {}}
                  aria-label="Search button"
                />
              </div>
            )}
            onChange={(e, val) => {
              setInputValue("");
              navigate(`/word/${val}`);
            }}
          />
          <Box
            component={"button"}
            type="submit"
            sx={{
              position: "absolute",
              top: 0,
              right: 2,
              border: "none",
              bgcolor: "transparent",
              cursor: "pointer",
              width: "32px",
              height: "32px",
              p: "0px",
            }}
          >
            <SearchRoundedIcon
              sx={{
                width: "22px",
                height: "22px",
                p: "5px",
              }}
            />
          </Box>
        </form>
      </Box>
    </>
  );
}

export default function Navbar({ threshold = 0, ...props }) {
  const { word } = useParams();
  const { bg, txt, removeOutline } = props;

  const navigation = [
    {
      name: "Word of the Day",
      link: `https://metanery.com/word/${String(
        getWordOfTheDay()
      ).toLowerCase()}`,
    },
    {
      name: "Thesaurus",
      link: `https://metanery.com/synonym/${word ? word : "lexicon"}`,
    },
    // { name: "Quiz" },
  ];

  const elevated = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: window ? window : undefined,
  });

  return (
    <header>
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          top: 0,
          bgcolor: bg,
          boxShadow: elevated ? "0px 1px 5px 0px rgba(0,0,0,0.05)" : "none",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="lg" sx={{ height: "50px", width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Logo color={txt} />
            <Box
              sx={{ display: { xs: "none", sm: "flex" }, flexDirection: "row" }}
            >
              {navigation.map((nav, i) => (
                <Typography
                  component={"a"}
                  key={"NavText" + i}
                  sx={{
                    fontSize: "14px",
                    ml: "14px",
                    color: txt,
                    textDecoration: "none",
                  }}
                  href={nav.link}
                >
                  {nav.name}
                </Typography>
              ))}
            </Box>
            <SearchBar color={txt} removeOutline={removeOutline} />
          </Box>
        </Container>
      </Box>
    </header>
  );
}

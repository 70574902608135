import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import WordLayout from "./WordLayout";

export default function WordPage() {
  const { word } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(Boolean(word));
  const [dictData, setDictData] = useState(undefined);

  async function getDictionary() {
    try {
      const response = await fetch(
        `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`
      );
      const data = await response.json();
      setDictData(data);
      console.log("dict", data);
    } catch (error) {
      if (dictData) setDictData(false);
    }
  }

  useEffect(() => {
    if (word) {
      getDictionary();
    } else {
      const nav = async () => {
        setTimeout(() => {
          if (location.pathname !== "/") {
            navigate("/");
          }
        });
      };

      nav();
    }
  }, [word]);

  useEffect(() => {
    if (dictData) {
      setLoading(false);
    }
  }, [dictData]);

  if (loading) {
    return <></>;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
      {word && <WordLayout word={word} dictData={dictData} />}
    </Box>
  );
}

import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { defaultFont, textColor } from "../../Styles/styles";
import WordBox from "../../Components/Containers/WordBox";
import OutlinedWords from "../../Components/Containers/OutlinedWords";
import WordTable from "../../Components/Containers/WordTable";
import { favouriteWords } from "../MainPage/words";
import SynonymsNumber from "../../Components/Containers/SynonymsNumber";
import { Motion } from "../../Components/Motion";
import SynonymContainer from "../SynonymPage/SynonymContainer";

export default function AntonymPage() {
  const { word } = useParams();
  const navigate = useNavigate();
  const [list, setList] = useState(null);

  async function getMuse() {
    if (!word) {
      setTimeout(() => {
        navigate("/");
      });
      return;
    }

    try {
      const response = await fetch(
        `https://api.datamuse.com/words?rel_ant=${word}&max=20`
      );
      const data = await response.json();
      if (data && data.length > 0) {
        setList(data.map((a) => a.word));
      } else {
        setList(null);
      }
    } catch (error) {
      console.log(error);
      setList(null);
    }
  }

  useEffect(() => {
    getMuse();
  }, [word]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: "100%",
        alignItems: "flex-start",
        alignContent: "flex-start",
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "16px",
          color: textColor,
        }}
      >
        {"Antonyms"}
      </Typography>
      <Motion>
        <Box sx={{ width: "fit-content", mb: "6px" }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              fontWeight: "bold",
              fontSize: "40px",
              lineHeight: "42px",
              width: "fit-content",
              color: textColor,
            }}
          >
            {word}
          </Typography>
        </Box>
      </Motion>
      <SynonymsNumber word={word} only="synonyms" />
      <SynonymContainer list={list} antonym={true} />

      <OutlinedWords word={word} />
      <OutlinedWords word={word} endpoint="sl" text="Sounds alike" />
      <OutlinedWords
        word={word}
        endpoint="lc"
        text="Words that follow in sentence"
        otherEndpoint="&sp=*"
        max={50}
        filterShort={4}
      />
      <WordTable title="Favourite words" wordsList={favouriteWords} />
    </Box>
  );
}

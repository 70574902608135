import React, { useEffect } from "react";

export default function AdMultiplex({
  horizontal = false,
  isFluid = true,
  index,
}) {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  const fluidAds = [
    "7557575406",
    "3235187015",
    "9090250555",
    "4791912895",
    "8295942001",
    "3837923878",
  ];

  function getFluidAdSlot() {
    if (index < fluidAds.length) {
      return fluidAds[index];
    }
    const randomIndex = Math.floor(Math.random() * fluidAds.length);
    return fluidAds[randomIndex];
  }

  if (isFluid) {
    return (
      <div style={{ marginTop: "26px" }}>
        <ins
          class="adsbygoogle"
          style={{ display: "block" }}
          data-ad-format="fluid"
          data-ad-layout-key="-fv+63+32-dc+cs"
          data-ad-client="ca-pub-5397475111148567"
          data-ad-slot={getFluidAdSlot()}
        ></ins>
      </div>
    );
  }

  if (horizontal) {
    return (
      <div style={{ marginTop: "26px" }}>
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-5397475111148567"
          data-ad-slot="5181088847"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>
    );
  }

  return (
    <div style={{ marginTop: "26px" }}>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-format="autorelaxed"
        data-ad-client="ca-pub-5397475111148567"
        data-ad-slot="5867461506"
      ></ins>
    </div>
  );
}

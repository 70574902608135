import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import WordPage from "./Resources/Pages/WordPage/WordPage";
import Navbar from "./Resources/Components/Navbar/Navbar";
import { Box, Container, createTheme, ThemeProvider } from "@mui/material";
import ScrollUpComp from "./Resources/Components/ScrollUpComp";
import coreThemeFile, { navPalette } from "./Resources/Styles/styles";
import MainPage from "./Resources/Pages/MainPage/MainPage";
import SynonymPage from "./Resources/Pages/SynonymPage/SynonymPage";
import AntonymPage from "./Resources/Pages/AntonymPage/AntonymPage";
import { useEffect, useState } from "react";
import Footer from "./Resources/Components/Footer/Footer";
import "./Resources/Firebase";
import Cookies from "./Resources/Pages/Cookies/Cookies";
import Helmet from "./Resources/Components/Helmet";
import CookieBar from "./Resources/Components/CookieBar";
import AboutUs from "./Resources/Components/Containers/AboutUs";
import RandomArticles from "./Resources/Components/Containers/RandomArticles";

export const theme = createTheme(coreThemeFile());

function AdsContainer({ children }) {
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "row",
        pt: "98px",
        //minHeight: "100vh",
      }}
    >
      <Box sx={{ width: "100%" }}>{children}</Box>
    </Container>
  );
}

function PageHolder({
  children,
  ads = true,
  aboutUs = true,
  randomArticles = true,
}) {
  const location = useLocation();
  const [colors, setColors] = useState(null);
  const { bg, txt } = colors || {};

  useEffect(() => {
    let locCol =
      colors || navPalette[Math.floor(Math.random() * navPalette.length)];
    let { bg: bgLoc, txt: txtLoc } = locCol;
    while (bgLoc === bg && txtLoc === txt) {
      locCol = navPalette[Math.floor(Math.random() * navPalette.length)];
      bgLoc = locCol.bg;
      txtLoc = locCol.txt;
    }
    setColors(locCol);
  }, [location]);

  return (
    <Helmet>
      <Box sx={{ position: "relative", minHeight: "100vh" }}>
        <Navbar {...colors} />
        {ads ? <AdsContainer>{children}</AdsContainer> : <>{children}</>}
        {randomArticles && <RandomArticles />}
        {aboutUs && <AboutUs />}
        <CookieBar {...colors} />
      </Box>
      <Footer {...colors} />
    </Helmet>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div
        className="App"
        style={{ minWidth: "300px", backgroundColor: "#fafafa" }}
      >
        <ScrollUpComp />
        <Routes>
          <Route
            path="*"
            element={
              <PageHolder>
                <MainPage />
              </PageHolder>
            }
          />
          <Route
            path="/"
            element={
              <PageHolder>
                <MainPage />
              </PageHolder>
            }
          />
          <Route
            path="/word/:word"
            element={
              <PageHolder>
                <WordPage />
              </PageHolder>
            }
          />
          <Route
            path="/synonym/:word"
            element={
              <PageHolder>
                <SynonymPage />
              </PageHolder>
            }
          />
          <Route
            path="/antonym/:word"
            element={
              <PageHolder>
                <AntonymPage />
              </PageHolder>
            }
          />
          <Route
            path="/cookies"
            element={
              <PageHolder randomArticles={false} aboutUs={false}>
                <Cookies />
              </PageHolder>
            }
          />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;

import { Box, Typography } from "@mui/material";
import React from "react";
import { textColor } from "../../Styles/styles";

function detectNouns(sentence) {
  // Regular expression to match words that might be nouns
  const nounPattern =
    /\b(?:[A-Z][a-z]*|\b(?:the|a|an)\s+\w+|\w+(?:tion|ment|ness|ity|ship|ance|ence|dom|hood|ism))\b/g;

  const additionalPattern = /\b[a-z]{6,}\b/g;
  let additionalMatches = sentence.match(additionalPattern) || [];

  const sentMatches = sentence?.match(nounPattern) || [];
  let matches = [...new Set([...additionalMatches, ...sentMatches])];

  if (!matches) return [];

  let finalArray = [];
  for (let i = 0; i < matches.length; i++) {
    const word = matches[i];
    if (/^(the|a|an)$/i.test(word)) {
      continue;
    }
    finalArray.push(word.replace(/\b(?:the|a|an)\s+/gi, "").trim());
  }

  // Return unique matches if found, otherwise an empty array
  return [...new Set(finalArray)];
}

const makeWordsLinked = (str, words) => {
  const regex = new RegExp(`\\b(${words.join("|")})\\b`, "gi"); // Create regex with all words in the list
  return str.split(regex).map((part, index) =>
    words.includes(part.toLowerCase()) ? (
      <Box
        component={"a"}
        sx={{
          color: textColor,
          fontWeight: "normal",
          "&:hover": {
            fontWeight: "bold",
            fontSize: "14px",
          },
        }}
        href={`https://metanery.com/word/${String(part).toLowerCase()}`}
        key={index}
      >
        {part}
      </Box>
    ) : (
      part
    )
  );
};

export default function LinkText({ text }) {
  if (!text) return <></>;
  return <>{makeWordsLinked(text, detectNouns(text))}</>;
}

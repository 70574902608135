import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { defaultFont, textColor } from "../../Styles/styles";

export default function SynonymsNumber({ word, only = false, sx = {} }) {
  const [count, setCount] = useState(null);
  const [antCount, antSetCount] = useState(null);

  async function getMuse() {
    if (!word || only === "antonyms") return;

    try {
      const response = await fetch(
        `https://api.datamuse.com/words?rel_syn=${word}&max=20`
      );
      const data = await response.json();
      if (data && data.length > 0) {
        setCount(data.length);
      } else {
        setCount(null);
      }
    } catch (error) {
      console.log(error);
      setCount(null);
    }
  }

  async function getMuseAntonyms() {
    if (!word || only === "synonyms") return;

    try {
      const response = await fetch(
        `https://api.datamuse.com/words?rel_ant=${word}&max=20`
      );
      const data = await response.json();
      if (data && data.length > 0) {
        antSetCount(data.length);
      } else {
        antSetCount(null);
      }
    } catch (error) {
      console.log(error);
      antSetCount(null);
    }
  }

  useEffect(() => {
    getMuse();
    getMuseAntonyms();
  }, [word]);

  if (!count && !antCount) return <></>;
  return (
    <Typography sx={{ fontSize: "15px", ...sx }}>
      Number of known
      <b>
        {count != null ? (
          <>
            {" "}
            synonyms: {count} (
            <Box
              component={"a"}
              sx={{ color: textColor }}
              href={`https://metanery.com/synonym/${String(
                word
              ).toLowerCase()}`}
            >
              show more
            </Box>
            )
          </>
        ) : (
          ""
        )}
      </b>
      <b>
        {antCount != null ? (
          <>
            {count != null ? `,` : ""} antonyms: {antCount} (
            <Box
              component={"a"}
              sx={{ color: textColor }}
              href={`https://metanery.com/antonym/${String(
                word
              ).toLowerCase()}`}
            >
              show more
            </Box>
            )
          </>
        ) : (
          ""
        )}
      </b>
    </Typography>
  );
}

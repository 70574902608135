import { Box, Typography } from "@mui/material";
import React from "react";
import { blackBorder, defaultFont, textColor } from "../../Styles/styles";
import { useNavigate } from "react-router-dom";
import { Motion } from "../Motion";

export default function WordBox({ word = "test asdt" }) {
  const navigate = useNavigate();

  return (
    <Motion>
      <Box
        sx={{
          py: "1px",
          px: "14px",
          ...blackBorder,
          maxWidth: "min-content",
          whiteSpace: "nowrap",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          bgcolor: "white",

          maxHeight: "28px",
          height: "28px",
          minHeight: "28px",
        }}
        onClick={() => {
          navigate(`/word/${String(word).toLowerCase()}`);
        }}
      >
        <Typography
          component={"a"}
          sx={{
            fontSize: "15px",
            textAlign: "center",
            color: textColor,
            textDecoration: "none",
            cursor: "pointer",
          }}
          href={`https://metanery.com/word/${String(word).toLowerCase()}`}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {word}
        </Typography>
      </Box>
    </Motion>
  );
}

import React from "react";
import { Box } from "@mui/material";
import DefinitionsLayout from "../../Components/Containers/DefinitionsLayout";
import OutlinedWords from "../../Components/Containers/OutlinedWords";
import WordTable from "../../Components/Containers/WordTable";
import { favouriteWords } from "../MainPage/words";

export default function WordLayout(props) {
  const { word, reWord } = props;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <DefinitionsLayout {...props} />
      <OutlinedWords word={word} reWord={reWord} />
      <OutlinedWords word={word} endpoint="sl" text="Sounds alike" />
      <OutlinedWords
        word={word}
        endpoint="lc"
        text="Words that follow in sentence"
        otherEndpoint="&sp=*"
        max={50}
        filterShort={4}
        reWord={reWord}
      />
      <WordTable title="Favourite words" wordsList={favouriteWords} />
    </Box>
  );
}

import {
  Autocomplete,
  Box,
  Container,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { blackBorder, defaultFont, textColor } from "../../Styles/styles";
import { useNavigate } from "react-router-dom";
import Books from "../Navbar/Books";
import { Motion } from "../Motion";
import CookieBar from "../CookieBar";
import CookieIcon from "@mui/icons-material/Cookie";
import { theme } from "../../../App";

function Logo({ color = textColor }) {
  return (
    <Box
      component="a"
      sx={{
        display: "flex",
        flexDirection: "row",

        alignItems: "center",
        alignContent: "center",
        textDecoration: "none",
      }}
      href="https://metanery.com/"
    >
      <Books fill={color} style={{ width: "24px", height: "24px" }} />

      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "900",
          mx: "7px",
          color,
          textDecoration: "none",
        }}
      >
        METANERY
      </Typography>
    </Box>
  );
}

function SearchBar({ color = "#000000", removeOutline = false }) {
  const [inputValue, setInputValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  // Debounce the input value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 250);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  useEffect(() => {
    if (debouncedValue && debouncedValue.trim() !== "") {
      checkSuggestions(debouncedValue);
    } else {
      setSuggestions([]);
    }
  }, [debouncedValue]);

  function checkSuggestions(inpVal) {
    const findSuggestions = async () => {
      try {
        const response = await fetch(
          `https://api.datamuse.com/sug?s=${inpVal.trim()}`
        );
        const data = await response.json();
        console.log("Sugg data ", data);
        if (!data || !data?.length > 0) return;
        setSuggestions(data.map((a) => a.word));
      } catch (error) {
        console.log("Sugg error ", error);
        setSuggestions([]);
      }
    };

    findSuggestions();
  }

  return (
    <>
      <Box sx={{ position: "relative", ml: "auto" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!inputValue || inputValue.trim() === "") return;
            setInputValue("");
            navigate(`/word/${inputValue}`);
          }}
          style={{}}
        >
          <Autocomplete
            disablePortal
            blurOnSelect
            options={suggestions}
            noOptionsText=""
            ListboxProps={{
              sx: {
                maxHeight: "200px",
                "& .MuiAutocomplete-option": {
                  minHeight: "30px",
                  textAlign: "left",
                  fontSize: "14px",
                  lineHeight: "15px",
                  borderBottom: "1px solid #e0e0e0",
                  fontFamily: defaultFont,
                },
              },
            }}
            sx={{
              "& .MuiAutocomplete-popper": {
                borderRadius: "8px",
                //border: `2px solid ${color}`,
              },
            }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <Box
                  {...params.inputProps}
                  sx={{
                    ...blackBorder,
                    border: removeOutline ? "none" : `2px solid ${color}`,
                    boxShadow: "none",
                    width: { xs: "70px", sm: "100px" },
                    height: "26px",
                    ml: "auto",
                    pl: "14px",
                    pr: "36px",
                    "::placeholder": {
                      fontSize: "13px",
                      color: textColor,
                      fontWeight: "bold",
                      fontFamily: defaultFont,
                    },
                  }}
                  component={"input"}
                  placeholder="Search"
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  onFocus={() => {}}
                  aria-label="Search button"
                />
              </div>
            )}
            onChange={(e, val) => {
              setInputValue("");
              navigate(`/word/${val}`);
            }}
          />
          <Box
            component={"button"}
            type="submit"
            sx={{
              position: "absolute",
              top: 0,
              right: 2,
              border: "none",
              bgcolor: "transparent",
              cursor: "pointer",
              width: "32px",
              height: "32px",
              p: "0px",
            }}
          >
            <SearchRoundedIcon
              sx={{
                width: "22px",
                height: "22px",
                p: "5px",
              }}
            />
          </Box>
        </form>
      </Box>
    </>
  );
}

export default function Footer({ threshold = 0, ...props }) {
  const [cookieOpen, setCookieOpen] = useState(false);
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const { bg, txt, removeOutline } = props;

  const elevated = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: window ? window : undefined,
  });

  return (
    <footer>
      <CookieBar open={cookieOpen} setOpen={setCookieOpen} {...props} />
      <Box
        sx={{
          mt: "48px",
          width: "100%",
          // position: "absolute",
          //bottom: 0,
          bgcolor: bg,
          boxShadow: elevated ? "0px -1px 5px 0px rgba(0,0,0,0.05)" : "none",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            height: "160px",
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            alignContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              py: "32px",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              alignContent: "flex-start",
            }}
          >
            <Logo color={txt} />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                ml: "auto",
                alignItems: "flex-end",
                alignContent: "flex-end",
                height: "100%",
              }}
            >
              <SearchBar color={txt} removeOutline={removeOutline} />
              <Motion>
                <Box
                  sx={{
                    mt: "auto",
                    width: { xs: "124px", sm: "154px" },
                    height: "31px",
                    bgcolor: txt,
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    cursor: "pointer",
                    opacity: 1,
                  }}
                  onClick={() => {
                    setCookieOpen(true);
                  }}
                >
                  <CookieIcon
                    sx={{
                      fill: bg,
                      mr: "auto",
                      width: "29px",
                      height: "29px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: bg,
                      fontWeight: "bold",
                      pr: { xs: "30px", sm: "22px" },
                      fontSize: "14px",
                      pt: "2px",
                    }}
                  >
                    {xs ? "Cookies" : "Cookies Policy"}
                  </Typography>
                </Box>
              </Motion>
            </Box>
          </Box>
        </Container>
      </Box>
    </footer>
  );
}

export const blackBorder = { borderRadius: "24px", border: `2px solid black` };
export const appBgColor = "#FBFBFB";
export const textColor = "#222222";
export const appGrey = "#D9D9D9";

export const navPalette = [
  // Blue
  { bg: "#0167F4", txt: "#FFFFFF" },
  { bg: "#001A49", txt: "#FFFFFF", removeOutline: true },
  { bg: "#000000", txt: "#FFFFFF" },
  { bg: "#E8ECEE", txt: "#000000" },
  // Red
  { bg: "#FAFAFA", txt: "#FE2C60" },
  { bg: "#E4E4E4", txt: "#FE2C60" },
  { bg: "#FE2C60", txt: "#FAFAFA" },
  { bg: "#141414", txt: "#FE2C60" },
  // Green
  { bg: "#00BC73", txt: "#FFFFFF" },
  { bg: "#004845", txt: "#00BC73", removeOutline: true },
  { bg: "#4E525A", txt: "#FFFFFF" },
  // Purple
  { bg: "#6126FF", txt: "#FFFFFF" },
  { bg: "#140D29", txt: "#7146FF", removeOutline: true },
  // Rest
  { bg: "#131314", txt: "#FCC001", removeOutline: true },
  { bg: "#003C55", txt: "#00D0FF", removeOutline: true },
  { bg: "#34202F", txt: "#FF5A19", removeOutline: true },
];

const coreThemeFile = () => {
  return {
    components: {
      MuiTypography: {
        defaultProps: {
          fontFamily: "'Baloo Thambi 2', sans-serif",
          variantMapping: {
            logo: "p",
          },
        },
        styleOverrides: {
          root: ({ ownerState }) => ({}),
        },
      },
    },
  };
};

export const defaultFont = "'Baloo Thambi 2'";

export default coreThemeFile;

import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
  setConsent,
} from "firebase/analytics";
import { fullConsent } from "./Components/CookieBar";

const firebaseConfig = {
  apiKey: "AIzaSyBA9keaa3PYJ1Rr-D0TRTFhrPIj0ZF0rOE",
  authDomain: "metanery-en.firebaseapp.com",
  projectId: "metanery-en",
  storageBucket: "metanery-en.appspot.com",
  messagingSenderId: "728149919820",
  appId: "1:728149919820:web:4f1462b9cd4c363887cce9",
  measurementId: "G-HE64SQEC7J",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

setAnalyticsCollectionEnabled(analytics, true);
setConsent(fullConsent);
